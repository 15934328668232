<script>
import get from 'lodash/get';
import includes from 'lodash/includes';
import { mapState } from 'vuex';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import crm from '@/domains/CRM/router/CRMRouterMap';
import { PageTabs, PageView } from '@/components';
import { COMPANY_SCOPES } from '../../store/CompanyModule';

export default {
  name: 'CompanySettingsView',
  components: {
    PageTabs,
    PageView,
  },
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      userPermissions: state => get(state, 'user.data.permissions'),
    }),
    ...mapState(DOMAINS_MODEL.crm.company, {
      isPrebookingEnabled: state => get(state[COMPANY_SCOPES.company], 'data.isPrebookingAllowed'),
    }),
  },
  async created() {
    this.tabs = [
      {
        label: {
          title: this.$t('CRM.Companies.Settings.premium.tabTitle'),
        },
        url: crm.companies.detail.settings.premium,
      },
      {
        label: {
          title: this.$t('CRM.Companies.Settings.costAllocations.tabTitle'),
        },
        url: crm.companies.detail.settings.costAllocations.list,
      },
      {
        label: {
          title: 'Whitelisted domains',
        },
        url: crm.companies.detail.settings.whitelistedDomains,
      },
    ];
    if (includes(this.userPermissions, CRM_PERMISSIONS.viewMappingCategories)) {
      this.tabs.push({
        label: {
          title: this.$t('CRM.Companies.Settings.mappingCategories.tabTitle'),
        },
        url: crm.companies.detail.settings.mappingCategories,
      });
    }
    if (this.isPrebookingEnabled) {
      this.tabs.push({
        label: {
          title: 'Company holidays',
        },
        url: crm.companies.detail.settings.companyHolidays,
      });
    }
  },
};
</script>
<template>
  <div
    class="CompanySettingsView"
    data-test-id="company_settings-view"
  >
    <PageTabs
      :tabs="tabs"
      data-test-id="tabs"
      are-inner-tabs
    />
    <PageView is-inner-tab-content>
      <RouterView />
    </PageView>
  </div>
</template>
