var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        attrs: {
          header: { title: "Delete this domain?" },
          size: _vm.SIZES.small,
          "backdrop-dismiss": "",
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("div", { staticClass: "emobg-color-ink" }, [
                  _c("span", [_vm._v('"' + _vm._s(_vm.domain.domain) + '"')]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c("DeleteButton", {
                  attrs: {
                    loading: _vm.isLoading,
                    "data-test-id": "delete-button",
                  },
                  on: { click: _vm.deleteDomain },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }