<script>
import get from 'lodash/get';
import { MuiAlgoliaSelect, MuiModal } from '@emobg/motion-ui/v1';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { CancelButton } from '@/components';

export default {
  components: {
    CancelButton,
    MuiAlgoliaSelect,
    MuiModal,
  },
  props: {
    dedicatedCategoryUuid: {
      type: String,
      required: true,
    },
    dedicatedCategoryName: {
      type: String,
      required: true,
    },
    openCategoryUuid: {
      type: String,
      default: '',
    },
    csOperatorUuid: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      categoryUuid: undefined,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      categoriesStatus: state => get(state, 'categories.STATUS'),
    }),
    openOperatorFilter() {
      return `cs_operator_uuid:${this.csOperatorUuid}`;
    },
  },
  created() {
    this.categoryUuid = this.openCategoryUuid;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.modalConfig = {
      title: this.$t('CRM.Companies.Settings.mappingCategories.modalTitle', {
        categoryName: this.dedicatedCategoryName,
      }),
      header: {
        class: 'pl-3',
        isClosable: true,
      },
    };
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'putOpenCategory',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async saveOpenCategory() {
      await this.putOpenCategory({
        dedicatedCategoryUuid: this.dedicatedCategoryUuid,
        openCategoryUuid: this.categoryUuid,
      });
      if (!this.categoriesStatus.ERROR) {
        this.notify({ message: this.$t('CRM.Companies.Settings.mappingCategories.successMessage') });
        this.$emit('close-modal');
      }
    },
    checkInvalidCategory(newUuid) {
      if (!newUuid) {
        this.categoryUuid = this.openCategoryUuid;
      }
    },
  },
};
</script>
<template>
  <MuiModal
    v-bind="modalConfig"
    is-open
    data-test-id="add_category-modal"
    @modal-closed="$emit('close-modal')"
  >
    <template slot="body">
      <MuiAlgoliaSelect
        v-model="categoryUuid"
        :index="ALGOLIA_INDEXES.vehicleCategories"
        :title="category => category.name"
        :label="$t('CRM.Companies.Settings.mappingCategories.input')"
        :filters="openOperatorFilter"
        placeholder="Select category"
        class="w-100"
        name="category"
        path-value="uuid"
        data-test-id="open_category-selector"
        @change="checkInvalidCategory"
      />
    </template>
    <template slot="footer">
      <div class="d-flex align-content-center justify-content-end w-100 p-3">
        <CancelButton
          data-test-id="cancel-button"
          @click="$emit('close-modal')"
        />
        <ui-button
          :loading="categoriesStatus.LOADING"
          :disabled="!categoryUuid"
          narrow
          data-test-id="save-button"
          @clickbutton="saveOpenCategory"
        >
          Add
        </ui-button>
      </div>
    </template>
  </MuiModal>
</template>
