var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    {
      attrs: {
        header: {
          title: _vm.$t(
            "CRM.Companies.Settings.costAllocations.createCodeTitle"
          ),
        },
      },
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c("MuiTextarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                      isPattern: {
                        pattern: /[^,\s][^\,]*[^,\s]*/,
                        message: "Format is not valid",
                      },
                    },
                    expression:
                      "{\n          isRequired: true,\n          isPattern: {\n            pattern: /[^,\\s][^\\,]*[^,\\s]*/,\n            message: 'Format is not valid',\n          },\n        }",
                  },
                ],
                attrs: {
                  label: "Code name*",
                  "data-test-id": "codes-input",
                  name: "codes",
                },
                model: {
                  value: _vm.codes,
                  callback: function ($$v) {
                    _vm.codes = $$v
                  },
                  expression: "codes",
                },
              }),
              _c("div", { staticClass: "mt-2" }, [
                _vm._v(" Example: code01, code02, code04, code05 "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                loading: _vm.costAllocationStatus.LOADING,
                disabled: !_vm.isFormValid,
                "data-test-id": "save-button",
              },
              on: { clickbutton: _vm.createCodes },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Common.Actions.save")) + " ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }