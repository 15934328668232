var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    _vm._b(
      {
        attrs: { "is-open": "", "data-test-id": "add_category-modal" },
        on: {
          "modal-closed": function ($event) {
            return _vm.$emit("close-modal")
          },
        },
      },
      "MuiModal",
      _vm.modalConfig,
      false
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("MuiAlgoliaSelect", {
            staticClass: "w-100",
            attrs: {
              index: _vm.ALGOLIA_INDEXES.vehicleCategories,
              title: (category) => category.name,
              label: _vm.$t("CRM.Companies.Settings.mappingCategories.input"),
              filters: _vm.openOperatorFilter,
              placeholder: "Select category",
              name: "category",
              "path-value": "uuid",
              "data-test-id": "open_category-selector",
            },
            on: { change: _vm.checkInvalidCategory },
            model: {
              value: _vm.categoryUuid,
              callback: function ($$v) {
                _vm.categoryUuid = $$v
              },
              expression: "categoryUuid",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-content-center justify-content-end w-100 p-3",
          },
          [
            _c("CancelButton", {
              attrs: { "data-test-id": "cancel-button" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close-modal")
                },
              },
            }),
            _c(
              "ui-button",
              {
                attrs: {
                  loading: _vm.categoriesStatus.LOADING,
                  disabled: !_vm.categoryUuid,
                  narrow: "",
                  "data-test-id": "save-button",
                },
                on: { clickbutton: _vm.saveOpenCategory },
              },
              [_vm._v(" Add ")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }