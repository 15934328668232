<script>
import get from 'lodash/get';
import { mapActions, mapMutations, mapState } from 'vuex';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent } from '@/components';
export default {
  components: {
    CancelButton,
    GenericModalComponent,
  },
  props: {
    companyUuid: {
      type: String,
      required: true,
    },
    costAllocationUuid: {
      type: String,
      required: true,
    },
    codeName: {
      type: String,
      required: true,
    },
    codeUuid: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.costAllocations, {
      costAllocationStatus: state => get(state, 'costAllocation.STATUS'),
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.costAllocations, [
      'deleteCostAllocationCode',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async deleteCode() {
      await this.deleteCostAllocationCode({
        companyUuid: this.companyUuid,
        costAllocationUuid: this.costAllocationUuid,
        costAllocationCodeUuid: this.codeUuid,
      });
      if (!this.costAllocationStatus.ERROR) {
        this.notify({ message: this.$t('CRM.Companies.Settings.costAllocations.deleteCode.successMessage', { codeName: this.codeName }) });
        this.$emit('close-modal');
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent :header="{ title: $t('CRM.Companies.Settings.costAllocations.deleteCode.title', { codeName }) }">
    <template slot="body">
      {{ $t('CRM.Companies.Settings.costAllocations.deleteCode.message') }}
    </template>
    <template slot="footer">
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('close-modal')"
      />
      <ui-button
        :color="COLORS.danger"
        :loading="costAllocationStatus.LOADING"
        data-test-id="delete-button"
        @clickbutton="deleteCode"
      >
        {{ $t('Common.Actions.delete') }}
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
