var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "WhitelistedDomainsCreateModal",
        attrs: {
          header: {
            title: "Add whitelisted domains",
            isClosable: true,
          },
          size: _vm.SIZES.large,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  {
                    ref: "form",
                    on: { areAllValid: (valid) => (_vm.isFormValid = valid) },
                  },
                  [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "div",
                        { staticClass: "w-100 mt-1 mr-2" },
                        [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  domainValidator: _vm.domainValidator,
                                  duplicatedDomainValidator:
                                    _vm.duplicatedDomainValidator,
                                },
                                expression:
                                  "{\n              domainValidator,\n              duplicatedDomainValidator,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              name: "date-name",
                              "data-test-id": "name-input",
                              label: "Domain name",
                              placeholder:
                                "Enter the end of the domain (e.g. example.com)",
                            },
                            model: {
                              value: _vm.currentDomain.domain,
                              callback: function ($$v) {
                                _vm.$set(_vm.currentDomain, "domain", $$v)
                              },
                              expression: "currentDomain.domain",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { class: !_vm.isFormValid ? "mt-1 pt-1" : "mt-4" },
                        [
                          _c(
                            "ui-button",
                            {
                              attrs: {
                                face: _vm.FACES.outline,
                                disabled:
                                  !_vm.isFormValid || !_vm.currentDomain.domain,
                                "data-test-id": "add-button",
                              },
                              on: { clickbutton: _vm.addDomain },
                            },
                            [_vm._v(" Add ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-4" },
                      _vm._l(_vm.orderedDomains, function (domain) {
                        return _c(
                          "div",
                          {
                            key: domain.domain,
                            staticClass:
                              "d-flex align-items-center emobg-border-bottom-1 emobg-border-color-ground-light py-1",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 text-left d-flex align-items-center",
                              },
                              [_vm._v(" " + _vm._s(domain.domain) + " ")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-items-center w-100",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "position-relative" },
                                  [
                                    _c("ui-checkbox", {
                                      attrs: {
                                        checked:
                                          domain.requiresEmailVerification,
                                        caption: "Email verification required",
                                      },
                                      on: {
                                        changevalue: ({ detail }) =>
                                          (domain.requiresEmailVerification =
                                            detail),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "ui-button",
                              {
                                staticClass: "pt-2 mb-2",
                                attrs: { face: _vm.FACES.text },
                                on: {
                                  clickbutton: function ($event) {
                                    return _vm.removeDomain(domain.domain)
                                  },
                                },
                              },
                              [
                                _c(
                                  "ui-tooltip",
                                  {
                                    attrs: {
                                      placement: _vm.PLACEMENTS.right,
                                      tooltip: "Remove",
                                    },
                                  },
                                  [
                                    _c("ui-icon", {
                                      attrs: {
                                        icon: _vm.ICONS.minus,
                                        color: _vm.GRAYSCALE.inkLight,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "SaveButton",
                  {
                    attrs: {
                      disabled: !_vm.isSaveButtonEnabled,
                      loading: _vm.isLoading,
                      "data-test-id": "save-button",
                    },
                    on: { click: _vm.saveDomains },
                  },
                  [_vm._v(" Add ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }