var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    {
      attrs: {
        header: {
          title: _vm.$t(
            "CRM.Companies.Settings.costAllocations.createCodeTitle",
            { codeName: _vm.codeName }
          ),
        },
      },
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c("MuiInputText", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true,\n        }",
                  },
                ],
                attrs: { "data-test-id": "code_name-input", name: "code-name" },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                loading: _vm.costAllocationStatus.LOADING,
                disabled: !_vm.isFormValid,
                "data-test-id": "save-button",
              },
              on: { clickbutton: _vm.editCode },
            },
            [_vm._v(" " + _vm._s(_vm.$t("Common.Actions.save")) + " ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }