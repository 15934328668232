var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-test-id": "cost-allocations-list" } },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Cost Allocation ")]),
      _c("ui-card", [
        _c("div", [
          _vm._v(
            _vm._s(_vm.$t("CRM.Companies.Settings.costAllocations.description"))
          ),
        ]),
        _c("h4", { staticClass: "mt-3" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("CRM.Companies.Settings.costAllocations.title")) +
              " "
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.companyStatus.LOADED,
                expression: "companyStatus.LOADED",
              },
            ],
            staticClass: "d-flex align-items-center mt-2",
          },
          [
            _c("ui-toggle", {
              staticClass: "emobg-border-1 emobg-border-color-ground-light p-2",
              attrs: {
                value: _vm.isActive,
                text: _vm.isActive ? "Yes" : "No",
                name: "isActive",
                "data-test-id": "active-input",
              },
              on: { changevalue: _vm.updateCostAllocationActivation },
            }),
            _c("div", { staticClass: "ml-3" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("CRM.Companies.Settings.costAllocations.toggleText")
                  ) +
                  " "
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm.isCostAllocationActive
        ? _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("MuiAlgoliaList", {
                ref: "costAllocationsTable",
                attrs: {
                  index: _vm.ALGOLIA_INDEXES.companyCostAllocations,
                  filters: _vm.listFilter,
                  "table-config": _vm.contentCells,
                  "item-actions": _vm.algoliaItemActions,
                  actions: _vm.algoliaActions,
                  "empty-message": _vm.getNoResultsLabel("cost allocations"),
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isDeleteModalVisible
        ? _c("CostAllocationDeleteModal", {
            attrs: {
              name: _vm.deleteCostAllocation.name,
              uuid: _vm.deleteCostAllocation.uuid,
              "company-uuid": _vm.company.uuid,
              "data-test-id": "delete-modal",
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
      _vm.isFormModalVisible
        ? _c("CostAllocationFormModal", {
            attrs: {
              "company-uuid": _vm.company.uuid,
              "editable-cost-allocation": _vm.editableCostAllocation,
              "data-test-id": "form-modal",
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }