<script>
import get from 'lodash/get';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { mapState } from 'vuex';
import { DELAY } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import MappingCategoriesEditModal from './MappingCategoriesEditModal/MappingCategoriesEditModal';
import { contentCells } from './contentCells';
import { COMPANY_SCOPES } from '../../../store/CompanyModule';

export default {
  name: 'CompanySettingsMappingCategoriesView',
  components: {
    MuiAlgoliaList,
    MappingCategoriesEditModal,
  },
  data() {
    return {
      isEditModalOpen: false,
      dedicatedCategoryUuid: undefined,
      dedicatedCategoryName: '',
      openCategoryUuid: undefined,
      currentCategoryName: '',
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      csOperatorUuid: state => get(state[COMPANY_SCOPES.company], 'data.csOperator.uuid'),
      dedicatedCsOperatorUuid: state => get(state[COMPANY_SCOPES.company], 'data.dedicatedFleetCsOperatorUuid'),
    }),
    tableFilter() {
      return `cs_operator_uuid:${this.dedicatedCsOperatorUuid}`;
    },
  },
  async created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells;
    this.algoliaItemActions = [
      {
        label: this.$t('CRM.Companies.Settings.mappingCategories.edit'),
        method: category => {
          this.dedicatedCategoryUuid = category.uuid;
          this.dedicatedCategoryName = category.name;
          this.openCategoryUuid = get(category, 'open_category.uuid');
          this.isEditModalOpen = true;
        },
      },
    ];
  },
  methods: {
    getNoResultsLabel,
    refreshAlgoliaStore,
    closeModal() {
      this.isEditModalOpen = false;
      this.refreshAlgoliaStore(this.$refs.mappingCategoriesTable, DELAY.long);
    },
  },
};
</script>
<template>
  <div
    class="CompanySettingsMappingCategoriesView"
    data-test-id="mapping_categories-view"
  >
    <h1 class="mb-2">
      {{ $t('CRM.Companies.Settings.mappingCategories.tabTitle') }}
    </h1>
    <MuiAlgoliaList
      ref="mappingCategoriesTable"
      :index="ALGOLIA_INDEXES.vehicleCategories"
      :table-config="contentCells"
      :item-actions="algoliaItemActions"
      :filters="tableFilter"
      :empty-message="getNoResultsLabel('categories')"
      data-test-id="vehicle_categories-table"
    />
    <MappingCategoriesEditModal
      v-if="isEditModalOpen"
      :dedicated-category-uuid="dedicatedCategoryUuid"
      :dedicated-category-name="dedicatedCategoryName"
      :open-category-uuid="openCategoryUuid"
      :cs-operator-uuid="csOperatorUuid"
      data-test-id="open_categories-modal"
      @close-modal="closeModal"
    />
  </div>
</template>
