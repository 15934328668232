var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        attrs: { header: null, size: _vm.SIZES.small, "backdrop-dismiss": "" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("h3", [_vm._v("Delete this holiday?")]),
                _c("div", { staticClass: "mt-3 emobg-color-ink-light" }, [
                  _c("span", [_vm._v(_vm._s(_vm.holiday.date))]),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      color: _vm.COLORS.danger,
                      loading: _vm.isLoading,
                      "data-test-id": "delete-button",
                    },
                    on: { clickbutton: _vm.deleteHoliday },
                  },
                  [_vm._v(" Delete ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }