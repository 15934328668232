<script>
import { DATE_FORMAT } from '@emobg/web-utils';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import get from 'lodash/get';
import map from 'lodash/map';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TableComponent } from '@/components';
import { HOLIDAYS_SCHEMA } from './const/index';
import CompanyHolidaysCreateModal from './CompanyHolidaysCreateModal/CompanyHolidaysCreateModal.vue';
import CompanyHolidaysDeleteModal from './CompanyHolidaysDeleteModal/CompanyHolidaysDeleteModal.vue';
import { COMPANY_SCOPES } from '../../../store/CompanyModule';

export default {
  name: 'CompanySettingsHolidaysView',
  components: {
    TableComponent,
    CompanyHolidaysCreateModal,
    CompanyHolidaysDeleteModal,
  },
  data() {
    return {
      showCreateModal: false,
      showDeleteModal: false,
      holidaysRowActions: [],
      selectedHoliday: undefined,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    ...mapState(DOMAINS_MODEL.crm.companyHolidays, {
      holidays: state => get(state, 'data.data.planning_config.workingHours.exceptions') || [],
    }),
    holidaysDates() {
      const holidays = keys(this.holidays);
      const shortHolidays = map(filter(holidays, date => moment(date, 'MM-DD', true).isValid()), date => ({
        date: moment(date, 'MM-DD').format('DD/MM'),
        isoDate: moment(date, 'MM-DD').toISOString(),
      }));
      const longHolidays = map(filter(holidays, date => moment(date, DATE_FORMAT.date, true).isValid()), date => ({
        date: moment(date, DATE_FORMAT.date).format(DATE_FORMAT.dob),
        isoDate: moment(date, DATE_FORMAT.date).toISOString(),
      }));

      return [
        ...orderBy(shortHolidays, 'isoDate', 'desc'),
        ...orderBy(longHolidays, 'isoDate', 'desc'),
      ];
    },
  },
  async created() {
    this.HOLIDAYS_SCHEMA = HOLIDAYS_SCHEMA;
    this.holidaysRowActions = [
      {
        label: 'Delete holiday',
        labelClass: 'emobg-color-danger emobg-font-weight-semibold',
        action: (holiday) => {
          this.selectedHoliday = holiday;
          this.showDeleteModal = true;
        },
      },
    ];
    await this.getPreBookingWorkingHours({ companyUuid: this.company.uuid });
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.companyHolidays, [
      'getPreBookingWorkingHours',
    ]),
  },
};
</script>
<template>
  <div
    class="CompanySettingsHolidaysView"
    data-test-id="company_holidays-view"
  >
    <div class="mb-2 d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <h2 class="d-flex align-items-center">
          Company holidays
        </h2>
        <ui-tooltip
          :placement="PLACEMENTS.right"
          tooltip="Non working days (affecting pre-bookings)"
          class="ml-1"
        >
          <ui-icon
            :icon="ICONS.infoFull"
            class="emobg-color-ink-light emobg-color-ink-hover"
          />
        </ui-tooltip>
      </div>
      <ui-button
        data-test-id="create-button"
        @clickbutton="showCreateModal = true"
      >
        Add holidays
      </ui-button>
    </div>
    <ui-card>
      <TableComponent
        :data="holidaysDates"
        :schema="HOLIDAYS_SCHEMA"
        :row-actions="holidaysRowActions"
        data-test-id="table"
      />
    </ui-card>
    <CompanyHolidaysCreateModal
      v-if="showCreateModal"
      :existing-holidays="holidaysDates"
      data-test-id="create-modal"
      @closeModal="showCreateModal = false"
    />
    <CompanyHolidaysDeleteModal
      v-if="showDeleteModal"
      :holiday="selectedHoliday"
      data-test-id="delete-modal"
      @closeModal="showDeleteModal = false"
    />
  </div>
</template>
