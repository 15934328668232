<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import get from 'lodash/get';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent } from '@/components';
import DeleteButton from '@/components/DeleteButton/DeleteButton';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';

export default {
  components: {
    DeleteButton,
    CancelButton,
    GenericModalComponent,
  },
  props: {
    domain: {
      type: Object,
      required: true,
    },
    refreshTable: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    ...mapState(DOMAINS_MODEL.crm.whitelistedDomains, {
      isError: state => get(state, 'STATUS.ERROR'),
      isLoading: state => get(state, 'STATUS.LOADING'),
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.whitelistedDomains, [
      'deleteWhitelistedDomains',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async deleteDomain() {
      await this.deleteWhitelistedDomains({ companyUuid: this.company.uuid, whitelistedDomainUuid: this.domain.uuid });
      if (!this.isError) {
        this.notify({ message: 'Domain successfully <span class="emobg-font-weight-semibold">deleted</span>' });
        this.refreshTable();
        this.$emit('closeModal');
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ title: 'Delete this domain?'}"
    :size="SIZES.small"
    backdrop-dismiss
    v-on="$listeners"
  >
    <template #body>
      <div class="emobg-color-ink">
        <span>"{{ domain.domain }}"</span>
      </div>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        :loading="isLoading"
        data-test-id="delete-button"
        @click="deleteDomain"
      />
    </template>
  </GenericModalComponent>
</template>
