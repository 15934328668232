<script>
import get from 'lodash/get';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY, navigationErrorHandler } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils';
import crm from '@domains/CRM/router/CRMRouterMap';
import { contentCells } from './contentCells';
import { COST_ALLOCATION_INPUT_TYPES } from './constants/const';
import CostAllocationDeleteModal from './CostAllocationDeleteModal/CostAllocationDeleteModal';
import CostAllocationFormModal from './CostAllocationsFormModal/CostAllocationsFormModal';
import { COMPANY_SCOPES } from '../../../store/CompanyModule';

export default {
  name: 'CompanyCostAllocationsListView',
  components: {
    MuiAlgoliaList,
    CostAllocationDeleteModal,
    CostAllocationFormModal,
  },
  data() {
    return {
      isActive: false,
      isDeleteModalVisible: false,
      isFormModalVisible: false,
      deleteCostAllocation: undefined,
      editableCostAllocation: undefined,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      isCostAllocationActive: state => get(state, 'company.data.allowCostAllocations'),
      companyStatus: state => get(state, 'company.STATUS'),
    }),
    ...mapState(DOMAINS_MODEL.crm.costAllocations, {
      costAllocationStatus: state => get(state, 'costAllocation.STATUS'),
    }),
    listFilter() {
      return `company_uuid:${this.company.uuid} AND parent_uuid:null`;
    },
  },
  created() {
    this.contentCells = contentCells;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.algoliaActions = [
      {
        label: this.$t('Common.Actions.create'),
        class: 'emobg-color-primary',
        method: () => {
          this.editableCostAllocation = undefined;
          this.isFormModalVisible = true;
        },
      },
    ];
    this.algoliaItemActions = [
      {
        label: this.$t('Common.Actions.edit'),
        method: result => {
          this.editableCostAllocation = result;
          this.isFormModalVisible = true;
        },
      },
      {
        label: this.$t('Common.Actions.delete'),
        type: 'danger',
        method: result => {
          this.deleteCostAllocation = result;
          this.isDeleteModalVisible = true;
        },
      },
      {
        label: 'Edit codes',
        isVisible: result => result.input_type === COST_ALLOCATION_INPUT_TYPES.select,
        method: result => {
          this.$router.push({
            name: crm.companies.detail.settings.costAllocations.codes,
            params: { costAllocationUuid: result.uuid },
          }).catch(navigationErrorHandler);
        },
      },
    ];

    this.isActive = this.isCostAllocationActive;
  },
  methods: {
    getNoResultsLabel,
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'getCompanyDetails',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.costAllocations, [
      'putConfigureCostAllocation',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async updateCostAllocationActivation({ detail }) {
      this.isActive = detail;
      if (this.isActive !== this.isCostAllocationActive) {
        await this.putConfigureCostAllocation({
          companyUuid: this.company.uuid,
          payload: {
            active: this.isActive,
          },
        });
        await this.getCompany(this.company.uuid);
        if (!this.costAllocationStatus.ERROR && this.costAllocationStatus.LOADED) {
          this.notify({
            message: this.isActive
              ? this.$t('CRM.Companies.Settings.costAllocations.activeMessage')
              : this.$t('CRM.Companies.Settings.costAllocations.inactiveMessage'),
          });
        }
      }
    },
    async getCompany(companyUuid) {
      await this.getCompanyDetails({ companyUuid });
      this.isActive = this.isCostAllocationActive;
    },
    closeModal() {
      this.isFormModalVisible = false;
      this.isDeleteModalVisible = false;
      this.refreshAlgoliaStore(this.$refs.costAllocationsTable, DELAY.long);
    },
    refreshAlgoliaStore,
  },
};
</script>
<template>
  <div data-test-id="cost-allocations-list">
    <h1 class="mb-2">
      Cost Allocation
    </h1>
    <ui-card>
      <div>{{ $t('CRM.Companies.Settings.costAllocations.description') }}</div>
      <h4 class="mt-3">
        {{ $t('CRM.Companies.Settings.costAllocations.title') }}
      </h4>
      <div
        v-show="companyStatus.LOADED"
        class="d-flex align-items-center mt-2"
      >
        <ui-toggle
          :value="isActive"
          :text="isActive ? 'Yes' : 'No'"
          name="isActive"
          class="emobg-border-1 emobg-border-color-ground-light p-2"
          data-test-id="active-input"
          @changevalue="updateCostAllocationActivation"
        />
        <div class="ml-3">
          {{ $t('CRM.Companies.Settings.costAllocations.toggleText') }}
        </div>
      </div>
    </ui-card>
    <div
      v-if="isCostAllocationActive"
      class="mt-3"
    >
      <MuiAlgoliaList
        ref="costAllocationsTable"
        :index="ALGOLIA_INDEXES.companyCostAllocations"
        :filters="listFilter"
        :table-config="contentCells"
        :item-actions="algoliaItemActions"
        :actions="algoliaActions"
        :empty-message="getNoResultsLabel('cost allocations')"
      />
    </div>
    <CostAllocationDeleteModal
      v-if="isDeleteModalVisible"
      :name="deleteCostAllocation.name"
      :uuid="deleteCostAllocation.uuid"
      :company-uuid="company.uuid"
      data-test-id="delete-modal"
      @close-modal="closeModal"
    />
    <CostAllocationFormModal
      v-if="isFormModalVisible"
      :company-uuid="company.uuid"
      :editable-cost-allocation="editableCostAllocation"
      data-test-id="form-modal"
      @close-modal="closeModal"
    />
  </div>
</template>
