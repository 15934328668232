import { sentenceCase } from '@emobg/web-utils';
export const contentCells = [
  {
    attributeName: 'name',
    title: 'Cost allocation name',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 120,
  },
  {
    attributeName: 'type',
    title: 'Level',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 120,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'input_type',
    title: 'Input type',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 120,
    transformValue: sentenceCase,
  },
  {
    attributeName: 'mandatory',
    title: 'Mandatory',
    displayPriority: 1,
    columnRatio: 1,
    minWidth: 120,
    transformValue: value => (value ? 'Yes' : 'No'),
  },
];
