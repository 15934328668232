var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CompanySettingsCostAllocationCodesView" },
    [
      _c(
        "h2",
        [
          _c("ui-icon", {
            staticClass: "cursor-pointer mx-2",
            attrs: { icon: _vm.ICONS.arrowLeft },
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
          }),
          _vm._v(" Cost Allocation Codes "),
        ],
        1
      ),
      _c("MuiAlgoliaList", {
        ref: "costAllocationCodesTable",
        staticClass: "mt-2",
        attrs: {
          index: _vm.ALGOLIA_INDEXES.companyCostAllocations,
          "table-config": _vm.contentCells,
          filters: _vm.codesFilter,
          "item-actions": _vm.algoliaItemActions,
          actions: _vm.algoliaActions,
        },
      }),
      _vm.isDeleteModalVisible
        ? _c("CostAllocationDeleteCodeModal", {
            attrs: {
              "company-uuid": _vm.companyUuid,
              "cost-allocation-uuid": _vm.costAllocationUuid,
              "code-uuid": _vm.editableCode.uuid,
              "code-name": _vm.editableCode.code,
            },
            on: { "close-modal": _vm.closeForm },
          })
        : _vm._e(),
      _vm.isEditModalVisible
        ? _c("CostAllocationEditCodeModal", {
            attrs: {
              "company-uuid": _vm.companyUuid,
              "cost-allocation-uuid": _vm.costAllocationUuid,
              "code-uuid": _vm.editableCode.uuid,
              "code-name": _vm.editableCode.code,
            },
            on: { "close-modal": _vm.closeForm },
          })
        : _vm._e(),
      _vm.isCreateModalVisible
        ? _c("CostAllocationCreateCodeModal", {
            attrs: {
              "company-uuid": _vm.companyUuid,
              "cost-allocation-uuid": _vm.costAllocationUuid,
            },
            on: { "close-modal": _vm.closeForm },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }