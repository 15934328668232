<script>
import {
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { mapActions, mapMutations, mapState } from 'vuex';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import each from 'lodash/each';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import findIndex from 'lodash/findIndex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent, SaveButton } from '@/components';
import { COMPANY_SCOPES } from '../../../../store/CompanyModule';
export default {
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiValidationWrapper,
    SaveButton,
  },
  directives: {
    Validate,
  },
  props: {
    refreshTable: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: undefined,
      currentDomain: {
        domain: undefined,
        requiresEmailVerification: false,
      },
      domains: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    ...mapState(DOMAINS_MODEL.crm.whitelistedDomains, {
      existingDomains: state => state.data.domains,
      isError: state => get(state, 'STATUS.ERROR'),
      isLoading: state => get(state, 'STATUS.LOADING'),
    }),
    isSaveButtonEnabled() {
      return !isEmpty(this.domains);
    },
    orderedDomains() {
      return orderBy(this.domains, 'name');
    },
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.whitelistedDomains, [
      'postWhitelistedDomains',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    orderBy,
    addDomain() {
      this.domains.push(cloneDeep(this.currentDomain));
      this.currentDomain.domain = undefined;
      this.currentDomain.requiresEmailVerification = false;
      this.resetValidation();
    },
    domainValidator() {
      const name = get(this.currentDomain, 'domain');
      const nameMatch = name.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/g);
      return { isValid: !isEmpty(nameMatch), message: 'This is not a valid domain name' };
    },
    duplicatedDomainValidator() {
      const domain = get(this.currentDomain, 'domain');
      const isDraftDuplicated = find(this.domains, { domain });
      const isExistingDuplicated = find(this.existingDomains, { domain });
      return { isValid: !isDraftDuplicated && !isExistingDuplicated, message: 'This domain name already exists' };
    },
    removeDomain(domain) {
      const domainIndex = findIndex(this.domains, { domain });
      this.domains.splice(domainIndex, 1);
    },
    resetValidation() {
      const validationManagers = get(this.$refs.form, 'validationManagers');
      this.$nextTick(() => {
        each(validationManagers, (validationManager) => validationManager.reset());
      });
    },
    async saveDomains() {
      await this.postWhitelistedDomains({
        companyUuid: this.company.uuid,
        data: { domains: this.domains },
      });
      if (!this.isError) {
        this.notify({ message: 'Domains successfully <span class="emobg-font-weight-semibold">added</span>' });
        this.refreshTable();
        this.$emit('closeModal');
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{
      title: 'Add whitelisted domains',
      isClosable: true
    }"
    :size="SIZES.large"
    class="WhitelistedDomainsCreateModal"
    v-on="$listeners"
  >
    <template #body>
      <MuiValidationWrapper
        ref="form"
        @areAllValid="valid => isFormValid = valid"
      >
        <div class="d-flex align-items-center">
          <div class="w-100 mt-1 mr-2">
            <MuiInputText
              v-model="currentDomain.domain"
              v-validate="{
                domainValidator,
                duplicatedDomainValidator,
              }"
              name="date-name"
              data-test-id="name-input"
              label="Domain name"
              class="w-100"
              placeholder="Enter the end of the domain (e.g. example.com)"
            />
          </div>
          <div :class="!isFormValid ? 'mt-1 pt-1' : 'mt-4'">
            <ui-button
              :face="FACES.outline"
              :disabled="!isFormValid || !currentDomain.domain"
              data-test-id="add-button"
              @clickbutton="addDomain"
            >
              Add
            </ui-button>
          </div>
        </div>
        <div class="mt-4">
          <div
            v-for="domain in orderedDomains"
            :key="domain.domain"
            class="d-flex align-items-center emobg-border-bottom-1 emobg-border-color-ground-light py-1"
          >
            <div class="w-100 text-left d-flex align-items-center">
              {{ domain.domain }}
            </div>
            <div class="d-flex align-items-center w-100">
              <div class="position-relative">
                <ui-checkbox
                  :checked="domain.requiresEmailVerification"
                  caption="Email verification required"
                  @changevalue="({ detail }) => domain.requiresEmailVerification = detail"
                />
              </div>
            </div>
            <ui-button
              :face="FACES.text"
              class="pt-2 mb-2"

              @clickbutton="removeDomain(domain.domain)"
            >
              <ui-tooltip
                :placement="PLACEMENTS.right"
                tooltip="Remove"
              >
                <ui-icon
                  :icon="ICONS.minus"
                  :color="GRAYSCALE.inkLight"
                />
              </ui-tooltip>
            </ui-button>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <SaveButton
        :disabled="!isSaveButtonEnabled"
        :loading="isLoading"
        data-test-id="save-button"
        @click="saveDomains"
      >
        Add
      </SaveButton>
    </template>
  </GenericModalComponent>
</template>
