<script>
import orderBy from 'lodash/orderBy';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { TableComponent } from '@/components';
import { DOMAINS_SCHEMA } from './const/index';
import WhitelistedDomainsCreateModal from './WhitelistedDomainsCreateModal/WhitelistedDomainsCreateModal';
import WhitelistedDomainsDeleteModal from './WhitelistedDomainsDeleteModal/WhitelistedDomainsDeleteModal';
import { COMPANY_SCOPES } from '../../../store/CompanyModule';

export default {
  name: 'CompanySettingsWhitelistedDomainsView',
  components: {
    TableComponent,
    WhitelistedDomainsCreateModal,
    WhitelistedDomainsDeleteModal,
  },
  data() {
    return {
      showCreateModal: false,
      showDeleteModal: false,
      selectedDomain: undefined,
      rowActions: [],
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    ...mapState(DOMAINS_MODEL.crm.whitelistedDomains, {
      domains: state => state.data.domains,
    }),
    orderedDomains() {
      return orderBy(this.domains, 'domain');
    },
  },
  created() {
    this.DOMAINS_SCHEMA = DOMAINS_SCHEMA;
    this.rowActions = [
      {
        label: 'Delete domain',
        labelClass: 'emobg-color-danger',
        action: domain => {
          this.selectedDomain = domain;
          this.showDeleteModal = true;
        },
      },
    ];
    this.getDomains();
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.whitelistedDomains, [
      'getWhitelistedDomains',
    ]),
    getDomains() {
      this.getWhitelistedDomains({ companyUuid: this.company.uuid });
    },
  },
};
</script>
<template>
  <div class="CompanySettingsWhitelistedDomainsView">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h1 class="mb-2">
          Whitelisted domains
        </h1>
        <ui-tooltip
          :placement="PLACEMENTS.right"
          tooltip="<span class='emobg-font-weight-bold'>Company domains</span><br/>A user signing up for the service
              with an email address ending with a domain added here (e.g. email@<span class='emobg-font-weight-bold'>
              example.com</span>), will sign up as an employee of this company"
          class="ml-2 mt-n1"
        >
          <ui-icon
            :icon="ICONS.infoFull"
            :size="ICONS_SIZES.medium"
            class="emobg-color-ink-light emobg-color-ink-hover"
          />
        </ui-tooltip>
      </div>
      <div class="mt-n3 d-flex align-items-center">
        <ui-button @clickbutton="showCreateModal = true">
          Add domains
        </ui-button>
      </div>
    </div>
    <ui-card>
      <TableComponent
        :data="orderedDomains"
        :row-actions="rowActions"
        :schema="DOMAINS_SCHEMA"
        empty-label="You haven't added any whitelisted domains yet"
      />
    </ui-card>
    <WhitelistedDomainsCreateModal
      v-if="showCreateModal"
      :refresh-table="getDomains"
      @closeModal="showCreateModal = false"
    />
    <WhitelistedDomainsDeleteModal
      v-if="showDeleteModal"
      :domain="selectedDomain"
      :refresh-table="getDomains"
      @closeModal="showDeleteModal = false"
    />
  </div>
</template>
