import { FALLBACK_MESSAGE } from '@emobg/web-utils';

export const DOMAINS_SCHEMA = [
  {
    header: 'Domain name',
    template: domain => domain.domain || FALLBACK_MESSAGE.dash,
  },
  {
    header: 'Email verification required',
    template: domain => domain.requiresEmailVerification ? 'Yes' : 'No',
  },
];
