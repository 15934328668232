export const contentCells = [
  {
    attributeName: 'name',
    title: 'Name',
    displayPriority: 1,
    minWidth: 200,
  },
  {
    attributeName: 'open_category.name',
    title: 'Maped category',
    displayPriority: 1,
    minWidth: 200,
  },
];
