var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanySettingsHolidaysView",
      attrs: { "data-test-id": "company_holidays-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "mb-2 d-flex justify-content-between align-items-center",
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center" },
            [
              _c("h2", { staticClass: "d-flex align-items-center" }, [
                _vm._v(" Company holidays "),
              ]),
              _c(
                "ui-tooltip",
                {
                  staticClass: "ml-1",
                  attrs: {
                    placement: _vm.PLACEMENTS.right,
                    tooltip: "Non working days (affecting pre-bookings)",
                  },
                },
                [
                  _c("ui-icon", {
                    staticClass: "emobg-color-ink-light emobg-color-ink-hover",
                    attrs: { icon: _vm.ICONS.infoFull },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.showCreateModal = true
                },
              },
            },
            [_vm._v(" Add holidays ")]
          ),
        ],
        1
      ),
      _c(
        "ui-card",
        [
          _c("TableComponent", {
            attrs: {
              data: _vm.holidaysDates,
              schema: _vm.HOLIDAYS_SCHEMA,
              "row-actions": _vm.holidaysRowActions,
              "data-test-id": "table",
            },
          }),
        ],
        1
      ),
      _vm.showCreateModal
        ? _c("CompanyHolidaysCreateModal", {
            attrs: {
              "existing-holidays": _vm.holidaysDates,
              "data-test-id": "create-modal",
            },
            on: {
              closeModal: function ($event) {
                _vm.showCreateModal = false
              },
            },
          })
        : _vm._e(),
      _vm.showDeleteModal
        ? _c("CompanyHolidaysDeleteModal", {
            attrs: {
              holiday: _vm.selectedHoliday,
              "data-test-id": "delete-modal",
            },
            on: {
              closeModal: function ($event) {
                _vm.showDeleteModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }