<script>
export default {
  name: 'CompanyCostAllocationsView',
};
</script>

<template>
  <div
    class="CostAllocationView"
    data-test-id="cost-allocations-view"
  >
    <RouterView />
  </div>
</template>
