<script>
import get from 'lodash/get';
import map from 'lodash/map';
import split from 'lodash/split';
import values from 'lodash/values';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiInputText,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { sentenceCase } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  GenericModalComponent,
} from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { COST_ALLOCATION_INPUT_TYPES, COST_ALLOCATION_LEVELS } from '../constants/const';

export default {
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiSelect,
    MuiTextarea,
    MuiValidationWrapper,
  },
  props: {
    editableCostAllocation: {
      type: Object,
      default: undefined,
    },
    companyUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormValid: false,
      costAllocation: {
        name: '',
        inputType: '',
        mandatory: false,
        type: COST_ALLOCATION_LEVELS.profile,
      },
      codes: '',
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.costAllocations, {
      costAllocationStatus: state => get(state, 'costAllocation.STATUS'),
    }),
    title() {
      return this.editableCostAllocation
        ? `Edit cost allocation "${this.editableCostAllocation.name}"`
        : 'Create cost allocation';
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.COST_ALLOCATION_INPUT_TYPES = COST_ALLOCATION_INPUT_TYPES;
    this.sentenceCase = sentenceCase;
    this.inputTypes = map(values(COST_ALLOCATION_INPUT_TYPES), value => ({ label: sentenceCase(value), value }));
    this.types = values(COST_ALLOCATION_LEVELS);
    if (this.editableCostAllocation) {
      this.costAllocation.name = this.editableCostAllocation.name;
      this.costAllocation.inputType = this.editableCostAllocation.input_type;
      this.costAllocation.mandatory = this.editableCostAllocation.mandatory;
      this.costAllocation.type = this.editableCostAllocation.type;
    }
  },
  methods: {
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    ...mapActions(DOMAINS_MODEL.crm.costAllocations, [
      'postCostAllocation',
      'putCostAllocation',
    ]),
    async saveCostAllocation() {
      if (this.editableCostAllocation) {
        await this.putCostAllocation({
          companyUuid: this.companyUuid,
          costAllocationUuid: this.editableCostAllocation.uuid,
          payload: this.costAllocation,
        });
      } else {
        await this.postCostAllocation({
          companyUuid: this.companyUuid,
          payload: {
            ...this.costAllocation,
            codes: split(this.codes, ','),
          },
        });
      }
      if (!this.costAllocationStatus.ERROR) {
        this.notify({ message: this.editableCostAllocation ? 'Cost allocation has been updated' : 'Cost allocation has been created' });
        this.$emit('close-modal');
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ title, isClosable: true }"
    v-on="$listeners"
    @modal-closed="$emit('close-modal')"
  >
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <MuiInputText
          v-model="costAllocation.name"
          v-validate="{
            isRequired: true,
          }"
          label="Cost allocation name*"
          placeholder="Enter a name"
          class="w-100"
          data-test-id="name-input"
          name="name"
        />
        <MuiSelect
          v-model="costAllocation.inputType"
          v-validate="{
            isRequired: true,
          }"
          :options="inputTypes"
          :disabled="!!editableCostAllocation"
          label="Input type*"
          placeholder="Select input type"
          name="inputType"
          data-test-id="input_type-input"
          class="w-100 mt-4"
        />
        <div class="d-flex">
          <ui-checkbox
            :checked="costAllocation.mandatory"
            caption="Is mandatory?"
            name="mandatory"
            class="mt-2"
            data-test-id="mandatory-input"
            @changevalue="({ detail }) => costAllocation.mandatory = detail"
          />
        </div>
        <div class="mt-4 emobg-body-medium emobg-font-weight-semibold">
          Cost allocation level*
        </div>
        <template v-if="!editableCostAllocation">
          <div
            v-for="type in types"
            :key="type"
          >
            <ui-radio
              :value="costAllocation.type"
              :option="type"
              :name="type"
              :caption="sentenceCase(type)"
              class="w-100 mt-2"
              data-test-id="type-input"
              @changevalue="() => costAllocation.type = type"
            /><br>
          </div>
        </template>
        <template v-else>
          <p class="mt-2">
            {{ sentenceCase(costAllocation.type) }}
          </p>
        </template>
        <template v-if="costAllocation.inputType === COST_ALLOCATION_INPUT_TYPES.select">
          <MuiTextarea
            v-model="codes"
            v-validate="{
              isRequired: true,
              isPattern: {
                pattern: /[^,\s][^\,]*[^,\s]*/,
                message: 'Format is not valid',
              },
            }"
            data-test-id="codes-input"
            name="codes"
            label="Cost allocation codes*"
            class="mb-2 mt-4"
            placeholder="Enter Cost Allocation codes separated with commas ( , ) "
          />
          <div class="mt-2">
            Example: code01, code02, code04, code05
          </div>
        </template>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('close-modal')"
      />
      <ui-button
        :loading="costAllocationStatus.LOADING"
        :disabled="!isFormValid"
        narrow
        data-test-id="save-button"
        @clickbutton="saveCostAllocation"
      >
        {{ editableCostAllocation ? 'Save' : 'Create' }}
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
