var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    {
      attrs: {
        header: { title: `Delete "${_vm.name}"?` },
        size: _vm.SIZES.small,
      },
    },
    [
      _c("template", { slot: "body" }, [
        _vm._v(
          " This will delete all information and codes. It cannot be undone. "
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c("DeleteButton", {
            attrs: {
              loading: _vm.costAllocationStatus.LOADING,
              "data-test-id": "delete-button",
            },
            on: { click: _vm.removeCostAllocation },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }