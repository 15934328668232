var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        attrs: {
          header: { title: "Add company holidays", isClosable: true },
          size: _vm.SIZES.large,
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  {
                    ref: "form",
                    on: { areAllValid: (valid) => (_vm.isFormValid = valid) },
                  },
                  [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "div",
                        { staticClass: "w-100 mr-2 mt-1" },
                        [
                          _c("MuiDatePicker", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  dateDuplicatedValidator:
                                    _vm.dateDuplicatedValidator,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              dateDuplicatedValidator,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              "valid-date-range": _vm.validDateRange,
                              "date-format-key": _vm.DATE_FORMAT_KEYS.dob,
                              label: "Date",
                              "data-test-id": "date-input",
                              name: "date",
                              "skip-time-step": "",
                            },
                            model: {
                              value: _vm.currentDate,
                              callback: function ($$v) {
                                _vm.currentDate = $$v
                              },
                              expression: "currentDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pt-1 mt-4" },
                        [
                          _c(
                            "ui-button",
                            {
                              attrs: {
                                face: _vm.FACES.outline,
                                disabled: _vm.isAddButtonDissabled,
                                "data-test-id": "add-button",
                              },
                              on: { clickbutton: _vm.addHoliday },
                            },
                            [_vm._v(" Add ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mt-4" },
                      _vm._l(_vm.orderedHolidays, function (holiday) {
                        return _c(
                          "div",
                          {
                            key: holiday.date,
                            staticClass:
                              "d-flex align-items-center emobg-border-bottom-1 emobg-border-color-ground-light py-1",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 text-left d-flex align-items-center",
                              },
                              [_vm._v(" " + _vm._s(holiday.date) + " ")]
                            ),
                            _c(
                              "ui-button",
                              {
                                attrs: {
                                  face: _vm.FACES.text,
                                  "data-test-id": "delete-button",
                                },
                                on: {
                                  clickbutton: function ($event) {
                                    return _vm.removeDate(holiday.date)
                                  },
                                },
                              },
                              [
                                _c(
                                  "ui-tooltip",
                                  {
                                    attrs: {
                                      placement: _vm.PLACEMENTS.right,
                                      tooltip: "Remove",
                                    },
                                  },
                                  [
                                    _c("ui-icon", {
                                      attrs: {
                                        icon: _vm.ICONS.minus,
                                        color: _vm.GRAYSCALE.inkLight,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  attrs: { "data-test-id": "cancel-button" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c(
                  "ui-button",
                  {
                    attrs: {
                      disabled: !_vm.isSaveButtonEnabled,
                      loading: _vm.isLoading,
                      "data-test-id": "save-button",
                    },
                    on: { clickbutton: _vm.updateHolidays },
                  },
                  [_vm._v(" Add holidays ")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }