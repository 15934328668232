<script>
import get from 'lodash/get';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiInputText, MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent } from '@/components';
export default {
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiValidationWrapper,
  },
  directives: {
    Validate,
  },
  props: {
    codeName: {
      type: String,
      required: true,
    },
    codeUuid: {
      type: String,
      required: true,
    },
    companyUuid: {
      type: String,
      required: true,
    },
    costAllocationUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      isFormValid: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.costAllocations, {
      costAllocationStatus: state => get(state, 'costAllocation.STATUS'),
    }),
  },
  created() {
    this.name = this.codeName;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.costAllocations, [
      'putCostAllocationCode',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async editCode() {
      await this.putCostAllocationCode({
        companyUuid: this.companyUuid,
        costAllocationUuid: this.costAllocationUuid,
        costAllocationCodeUuid: this.codeUuid,
        payload: {
          code: this.name,
        },
      });
      if (!this.costAllocationStatus.ERROR) {
        this.notify({ message: 'Code was updated' });
        this.$emit('close-modal');
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent :header="{ title: $t('CRM.Companies.Settings.costAllocations.createCodeTitle', { codeName }) }">
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <MuiInputText
          v-model="name"
          v-validate="{
            isRequired: true,
          }"
          data-test-id="code_name-input"
          name="code-name"
        />
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('close-modal')"
      />
      <ui-button
        :loading="costAllocationStatus.LOADING"
        :disabled="!isFormValid"
        data-test-id="save-button"
        @clickbutton="editCode"
      >
        {{ $t('Common.Actions.save') }}
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
