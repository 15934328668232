<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils';
import { contentCells } from './contentCells';
import CostAllocationDeleteCodeModal from './CostAllocationDeleteCodeModal/CostAllocationDeleteCodeModal';
import CostAllocationEditCodeModal from './CostAllocationEditCodeModal/CostAllocationEditCodeModal';
import CostAllocationCreateCodeModal from './CostAllocationCreateCodeModal/CostAllocationCreateCodeModal';
import { COMPANY_SCOPES } from '../../../../store/CompanyModule';

export default {
  name: 'CompanySettingsCostAllocationCodesView',
  components: {
    MuiAlgoliaList,
    CostAllocationDeleteCodeModal,
    CostAllocationEditCodeModal,
    CostAllocationCreateCodeModal,
  },
  data() {
    return {
      editableCode: undefined,
      isDeleteModalVisible: false,
      isEditModalVisible: false,
      isCreateModalVisible: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    codesFilter() {
      return `parent_uuid:${this.costAllocationUuid}`;
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells;
    this.costAllocationUuid = get(this.$route, 'params.costAllocationUuid');
    this.companyUuid = this.company.uuid;
    this.algoliaItemActions = [
      {
        label: this.$t('Common.Actions.edit'),
        method: result => {
          this.editableCode = result;
          this.isEditModalVisible = true;
        },
      },
      {
        label: this.$t('Common.Actions.delete'),
        type: 'danger',
        method: result => {
          this.editableCode = result;
          this.isDeleteModalVisible = true;
        },
      },
    ];
    this.algoliaActions = [
      {
        label: this.$t('Common.Actions.create'),
        class: 'emobg-color-primary',
        method: () => {
          this.isCreateModalVisible = true;
        },
      },
    ];
  },
  methods: {
    closeForm() {
      this.isDeleteModalVisible = false;
      this.isEditModalVisible = false;
      this.isCreateModalVisible = false;
      this.refreshAlgoliaStore(this.$refs.costAllocationCodesTable, DELAY.long);
    },
    refreshAlgoliaStore,
  },
};
</script>
<template>
  <div class="CompanySettingsCostAllocationCodesView">
    <h2>
      <ui-icon
        :icon="ICONS.arrowLeft"
        class="cursor-pointer mx-2"
        @click="$router.back()"
      />
      Cost Allocation Codes
    </h2>
    <MuiAlgoliaList
      ref="costAllocationCodesTable"
      :index="ALGOLIA_INDEXES.companyCostAllocations"
      :table-config="contentCells"
      :filters="codesFilter"
      :item-actions="algoliaItemActions"
      :actions="algoliaActions"
      class="mt-2"
    />
    <CostAllocationDeleteCodeModal
      v-if="isDeleteModalVisible"
      :company-uuid="companyUuid"
      :cost-allocation-uuid="costAllocationUuid"
      :code-uuid="editableCode.uuid"
      :code-name="editableCode.code"
      @close-modal="closeForm"
    />
    <CostAllocationEditCodeModal
      v-if="isEditModalVisible"
      :company-uuid="companyUuid"
      :cost-allocation-uuid="costAllocationUuid"
      :code-uuid="editableCode.uuid"
      :code-name="editableCode.code"
      @close-modal="closeForm"
    />
    <CostAllocationCreateCodeModal
      v-if="isCreateModalVisible"
      :company-uuid="companyUuid"
      :cost-allocation-uuid="costAllocationUuid"
      @close-modal="closeForm"
    />
  </div>
</template>
