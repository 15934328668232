<script>
import get from 'lodash/get';
import split from 'lodash/split';
import { mapActions, mapMutations, mapState } from 'vuex';
import { MuiTextarea, MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent } from '@/components';
export default {
  components: {
    CancelButton,
    GenericModalComponent,
    MuiTextarea,
    MuiValidationWrapper,
  },
  directives: {
    Validate,
  },
  props: {
    companyUuid: {
      type: String,
      required: true,
    },
    costAllocationUuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      codes: '',
      isFormValid: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.costAllocations, {
      costAllocationStatus: state => get(state, 'costAllocation.STATUS'),
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.costAllocations, [
      'postCostAllocationCode',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async createCodes() {
      await this.postCostAllocationCode({
        companyUuid: this.companyUuid,
        costAllocationUuid: this.costAllocationUuid,
        payload: {
          codes: split(this.codes, ','),
        },
      });
      if (!this.costAllocationStatus.ERROR) {
        this.notify({ message: 'Codes were added to cost allocation' });
        this.$emit('close-modal');
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent :header="{ title: $t('CRM.Companies.Settings.costAllocations.createCodeTitle') }">
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <MuiTextarea
          v-model="codes"
          v-validate="{
            isRequired: true,
            isPattern: {
              pattern: /[^,\s][^\,]*[^,\s]*/,
              message: 'Format is not valid',
            },
          }"
          label="Code name*"
          data-test-id="codes-input"
          name="codes"
        />
        <div class="mt-2">
          Example: code01, code02, code04, code05
        </div>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('close-modal')"
      />
      <ui-button
        :loading="costAllocationStatus.LOADING"
        :disabled="!isFormValid"
        data-test-id="save-button"
        @clickbutton="createCodes"
      >
        {{ $t('Common.Actions.save') }}
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
