var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CompanySettingsPremiumView" },
    [
      _c("h1", { staticClass: "mb-2" }, [_vm._v(" Premium ")]),
      _c(
        "ui-card",
        { attrs: { "data-test-id": "premium-view" } },
        [
          _c("ui-toggle", {
            attrs: {
              value: _vm.isPremium,
              text: _vm.isPremium ? "Yes" : "No",
              label: _vm.$t("CRM.Companies.Settings.premium.label"),
              disabled: _vm.updateCompanyConfigStatus.LOADING,
              "data-test-id": "form-toggle",
              name: "premium",
            },
            on: {
              changevalue: ({ detail }) => {
                _vm.isPremium = detail
                _vm.savePremium()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }