<script>
import get from 'lodash/get';
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  GenericModalComponent,
} from '@/components';
export default {
  components: {
    CancelButton,
    DeleteButton,
    GenericModalComponent,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    companyUuid: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.costAllocations, {
      costAllocationStatus: state => get(state, 'costAllocation.STATUS'),
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.costAllocations, [
      'deleteCostAllocation',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async removeCostAllocation() {
      await this.deleteCostAllocation({
        companyUuid: this.companyUuid,
        uuid: this.uuid,
      });
      if (!this.costAllocationStatus.ERROR) {
        this.notify({ message: 'Cost allocation has been deleted' });
        this.$emit('close-modal');
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ title: `Delete &quot;${name}&quot;?` }"
    :size="SIZES.small"
  >
    <template slot="body">
      This will delete all information and codes. It cannot be undone.
    </template>
    <template slot="footer">
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('close-modal')"
      />
      <DeleteButton
        :loading="costAllocationStatus.LOADING"
        data-test-id="delete-button"
        @click="removeCostAllocation"
      />
    </template>
  </GenericModalComponent>
</template>
