var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanySettingsView",
      attrs: { "data-test-id": "company_settings-view" },
    },
    [
      _c("PageTabs", {
        attrs: { tabs: _vm.tabs, "data-test-id": "tabs", "are-inner-tabs": "" },
      }),
      _c(
        "PageView",
        { attrs: { "is-inner-tab-content": "" } },
        [_c("RouterView")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }