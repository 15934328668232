var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CompanySettingsWhitelistedDomainsView" },
    [
      _c("div", { staticClass: "d-flex justify-content-between" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center" },
          [
            _c("h1", { staticClass: "mb-2" }, [
              _vm._v(" Whitelisted domains "),
            ]),
            _c(
              "ui-tooltip",
              {
                staticClass: "ml-2 mt-n1",
                attrs: {
                  placement: _vm.PLACEMENTS.right,
                  tooltip:
                    "<span class='emobg-font-weight-bold'>Company domains</span><br/>A user signing up for the service\n            with an email address ending with a domain added here (e.g. email@<span class='emobg-font-weight-bold'>\n            example.com</span>), will sign up as an employee of this company",
                },
              },
              [
                _c("ui-icon", {
                  staticClass: "emobg-color-ink-light emobg-color-ink-hover",
                  attrs: {
                    icon: _vm.ICONS.infoFull,
                    size: _vm.ICONS_SIZES.medium,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-n3 d-flex align-items-center" },
          [
            _c(
              "ui-button",
              {
                on: {
                  clickbutton: function ($event) {
                    _vm.showCreateModal = true
                  },
                },
              },
              [_vm._v(" Add domains ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "ui-card",
        [
          _c("TableComponent", {
            attrs: {
              data: _vm.orderedDomains,
              "row-actions": _vm.rowActions,
              schema: _vm.DOMAINS_SCHEMA,
              "empty-label": "You haven't added any whitelisted domains yet",
            },
          }),
        ],
        1
      ),
      _vm.showCreateModal
        ? _c("WhitelistedDomainsCreateModal", {
            attrs: { "refresh-table": _vm.getDomains },
            on: {
              closeModal: function ($event) {
                _vm.showCreateModal = false
              },
            },
          })
        : _vm._e(),
      _vm.showDeleteModal
        ? _c("WhitelistedDomainsDeleteModal", {
            attrs: {
              domain: _vm.selectedDomain,
              "refresh-table": _vm.getDomains,
            },
            on: {
              closeModal: function ($event) {
                _vm.showDeleteModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }