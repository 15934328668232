<script>
import { DATE_FORMAT, DATE_FORMAT_KEYS } from '@emobg/web-utils';
import { MuiDatePicker } from '@emobg/vue-base';
import { MuiValidationWrapper, Validate } from '@emobg/motion-ui/v1';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import toNumber from 'lodash/toNumber';
import set from 'lodash/set';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import reverse from 'lodash/reverse';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent } from '@/components';
import { COMPANY_SCOPES } from '../../../../store/CompanyModule';

export default {
  name: 'CompanyHolidaysCreateModal',
  components: {
    CancelButton,
    GenericModalComponent,
    MuiValidationWrapper,
    MuiDatePicker,
  },
  directives: {
    Validate,
  },
  props: {
    existingHolidays: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isFormValid: undefined,
      currentDate: null,
      holidays: [],
      validDateRange: {
        start: undefined,
        end: undefined,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    ...mapState(DOMAINS_MODEL.crm.companyHolidays, {
      isError: state => get(state, 'STATUS.ERROR'),
      isLoading: state => get(state, 'STATUS.LOADING'),
      workingHours: state => get(state, 'data.data.planning_config.workingHours'),
    }),
    isSaveButtonEnabled() {
      return !isEmpty(this.holidays);
    },
    orderedHolidays() {
      return reverse(sortBy(this.holidays, holiday => toNumber(moment(holiday.date, DATE_FORMAT.dob).format('X'))));
    },
    isAddButtonDissabled() {
      return !this.isFormValid || !this.currentDate;
    },
  },
  created() {
    this.DATE_FORMAT_KEYS = DATE_FORMAT_KEYS;
    this.DATE_FORMAT = DATE_FORMAT;
    this.validDateRange.start = moment();
    this.validDateRange.end = moment('2050-12-31');
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.companyHolidays, [
      'getPreBookingWorkingHours',
      'patchPreBookingWorkingHours',
    ]),
    async updateHolidays() {
      const payload = cloneDeep(this.workingHours);
      if (isEmpty(payload.exceptions)) {
        payload.exceptions = {};
      }
      forEach(this.holidays, holiday => {
        set(payload, `exceptions[${moment(holiday.date, DATE_FORMAT.dob).format(DATE_FORMAT.date)}]`, []);
      });
      await this.patchPreBookingWorkingHours({ companyUuid: this.company.uuid, data: { working_hours: payload } });
      if (!this.isError) {
        this.$notify({ message: 'Company holidays succesfully <span class="emobg-font-weight-semibold">added</span>' });
        await this.getPreBookingWorkingHours({ companyUuid: this.company.uuid });
        this.$emit('closeModal');
      }
    },
    addHoliday() {
      this.holidays.push({ date: moment(this.currentDate).format(DATE_FORMAT.dob) });
      this.currentDate = null;
      this.validate();
      this.resetValidation();
    },
    dateDuplicatedValidator() {
      const dateWithYear = moment(this.currentDate).format(DATE_FORMAT.dob);
      const dateWithoutYear = moment(this.currentDate).format('DD/MM');
      const isValid = !find([...this.holidays, ...this.existingHolidays], ({ date }) => includes([dateWithYear, dateWithoutYear], date));
      return { isValid, message: 'This date already exists' };
    },
    removeDate(date) {
      const index = findIndex(this.holidays, { date });
      if (index >= 0) {
        this.holidays.splice(index, 1);
      }
      if (this.currentDate) {
        this.validate();
      }
    },
    resetValidation() {
      const validationManagers = get(this.$refs.form, 'validationManagers');
      this.$nextTick(() => {
        forEach(validationManagers, (validationManager) => validationManager.reset());
      });
    },
    validate() {
      const validationManagers = get(this.$refs.form, 'validationManagers');
      this.$nextTick(() => {
        forEach(validationManagers, (validationManager) => validationManager.validate());
      });
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ title: 'Add company holidays', isClosable: true }"
    :size="SIZES.large"
    v-on="$listeners"
  >
    <template #body>
      <MuiValidationWrapper
        ref="form"
        @areAllValid="valid => isFormValid = valid"
      >
        <div class="d-flex align-items-center">
          <div class="w-100 mr-2 mt-1">
            <MuiDatePicker
              v-model="currentDate"
              v-validate="{
                isRequired: true,
                dateDuplicatedValidator,
              }"
              :valid-date-range="validDateRange"
              :date-format-key="DATE_FORMAT_KEYS.dob"
              label="Date"
              data-test-id="date-input"
              name="date"
              class="w-100"
              skip-time-step
            />
          </div>
          <div class="pt-1 mt-4">
            <ui-button
              :face="FACES.outline"
              :disabled="isAddButtonDissabled"
              data-test-id="add-button"
              @clickbutton="addHoliday"
            >
              Add
            </ui-button>
          </div>
        </div>
        <div class="mt-4">
          <div
            v-for="holiday in orderedHolidays"
            :key="holiday.date"
            class="d-flex align-items-center emobg-border-bottom-1 emobg-border-color-ground-light py-1"
          >
            <div class="w-100 text-left d-flex align-items-center">
              {{ holiday.date }}
            </div>
            <ui-button
              :face="FACES.text"
              data-test-id="delete-button"
              @clickbutton="removeDate(holiday.date)"
            >
              <ui-tooltip
                :placement="PLACEMENTS.right"
                tooltip="Remove"
              >
                <ui-icon
                  :icon="ICONS.minus"
                  :color="GRAYSCALE.inkLight"
                />
              </ui-tooltip>
            </ui-button>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <ui-button
        :disabled="!isSaveButtonEnabled"
        :loading="isLoading"
        data-test-id="save-button"
        @clickbutton="updateHolidays"
      >
        Add holidays
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
