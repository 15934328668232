<script>
import moment from 'moment';
import unset from 'lodash/unset';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState } from 'vuex';
import { DATE_FORMAT } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent } from '@/components';
import { COMPANY_SCOPES } from '../../../../store/CompanyModule';

export default {
  name: 'CompanyHolidaysDeleteModal',
  components: {
    CancelButton,
    GenericModalComponent,
  },
  props: {
    holiday: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
    }),
    ...mapState(DOMAINS_MODEL.crm.companyHolidays, {
      isError: state => get(state, 'STATUS.ERROR'),
      isLoading: state => get(state, 'STATUS.LOADING'),
      workingHours: state => get(state, 'data.data.planning_config.workingHours'),
    }),
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.companyHolidays, [
      'getPreBookingWorkingHours',
      'patchPreBookingWorkingHours',
    ]),
    async deleteHoliday() {
      const payload = cloneDeep(this.workingHours);
      const isShortDateFormat = moment(this.holiday.date, 'DD/MM', true).isValid();
      const formatedDate = isShortDateFormat ? moment(this.holiday.date, 'DD/MM').format('MM-DD') : moment(this.holiday.date, DATE_FORMAT.dob).format(DATE_FORMAT.date);
      unset(payload, `exceptions['${formatedDate}']`);
      await this.patchPreBookingWorkingHours({ companyUuid: this.company.uuid, data: { working_hours: payload } });
      if (!this.isError) {
        this.$notify({ message: 'Company holidays successfully <span class="emobg-font-weight-semibold">deleted</span>' });
        await this.getPreBookingWorkingHours({ companyUuid: this.company.uuid });
        this.$emit('closeModal');
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    backdrop-dismiss
    v-on="$listeners"
  >
    <template #body>
      <h3>Delete this holiday?</h3>
      <div class="mt-3 emobg-color-ink-light">
        <span>{{ holiday.date }}</span>
      </div>
    </template>
    <template #footer>
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <ui-button
        :color="COLORS.danger"
        :loading="isLoading"
        data-test-id="delete-button"
        @clickbutton="deleteHoliday"
      >
        Delete
      </ui-button>
    </template>
  </GenericModalComponent>
</template>
