var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        attrs: { header: { title: _vm.title, isClosable: true } },
        on: {
          "modal-closed": function ($event) {
            return _vm.$emit("close-modal")
          },
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c("MuiInputText", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true,\n        }",
                  },
                ],
                staticClass: "w-100",
                attrs: {
                  label: "Cost allocation name*",
                  placeholder: "Enter a name",
                  "data-test-id": "name-input",
                  name: "name",
                },
                model: {
                  value: _vm.costAllocation.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.costAllocation, "name", $$v)
                  },
                  expression: "costAllocation.name",
                },
              }),
              _c("MuiSelect", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      isRequired: true,
                    },
                    expression: "{\n          isRequired: true,\n        }",
                  },
                ],
                staticClass: "w-100 mt-4",
                attrs: {
                  options: _vm.inputTypes,
                  disabled: !!_vm.editableCostAllocation,
                  label: "Input type*",
                  placeholder: "Select input type",
                  name: "inputType",
                  "data-test-id": "input_type-input",
                },
                model: {
                  value: _vm.costAllocation.inputType,
                  callback: function ($$v) {
                    _vm.$set(_vm.costAllocation, "inputType", $$v)
                  },
                  expression: "costAllocation.inputType",
                },
              }),
              _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("ui-checkbox", {
                    staticClass: "mt-2",
                    attrs: {
                      checked: _vm.costAllocation.mandatory,
                      caption: "Is mandatory?",
                      name: "mandatory",
                      "data-test-id": "mandatory-input",
                    },
                    on: {
                      changevalue: ({ detail }) =>
                        (_vm.costAllocation.mandatory = detail),
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "mt-4 emobg-body-medium emobg-font-weight-semibold",
                },
                [_vm._v(" Cost allocation level* ")]
              ),
              !_vm.editableCostAllocation
                ? _vm._l(_vm.types, function (type) {
                    return _c(
                      "div",
                      { key: type },
                      [
                        _c("ui-radio", {
                          staticClass: "w-100 mt-2",
                          attrs: {
                            value: _vm.costAllocation.type,
                            option: type,
                            name: type,
                            caption: _vm.sentenceCase(type),
                            "data-test-id": "type-input",
                          },
                          on: {
                            changevalue: () => (_vm.costAllocation.type = type),
                          },
                        }),
                        _c("br"),
                      ],
                      1
                    )
                  })
                : [
                    _c("p", { staticClass: "mt-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.sentenceCase(_vm.costAllocation.type)) +
                          " "
                      ),
                    ]),
                  ],
              _vm.costAllocation.inputType ===
              _vm.COST_ALLOCATION_INPUT_TYPES.select
                ? [
                    _c("MuiTextarea", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                            isPattern: {
                              pattern: /[^,\s][^\,]*[^,\s]*/,
                              message: "Format is not valid",
                            },
                          },
                          expression:
                            "{\n            isRequired: true,\n            isPattern: {\n              pattern: /[^,\\s][^\\,]*[^,\\s]*/,\n              message: 'Format is not valid',\n            },\n          }",
                        },
                      ],
                      staticClass: "mb-2 mt-4",
                      attrs: {
                        "data-test-id": "codes-input",
                        name: "codes",
                        label: "Cost allocation codes*",
                        placeholder:
                          "Enter Cost Allocation codes separated with commas ( , ) ",
                      },
                      model: {
                        value: _vm.codes,
                        callback: function ($$v) {
                          _vm.codes = $$v
                        },
                        expression: "codes",
                      },
                    }),
                    _c("div", { staticClass: "mt-2" }, [
                      _vm._v(" Example: code01, code02, code04, code05 "),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("close-modal")
              },
            },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                loading: _vm.costAllocationStatus.LOADING,
                disabled: !_vm.isFormValid,
                narrow: "",
                "data-test-id": "save-button",
              },
              on: { clickbutton: _vm.saveCostAllocation },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.editableCostAllocation ? "Save" : "Create") +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }