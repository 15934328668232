<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { COMPANY_SCOPES } from '../../../store/CompanyModule';

export default {
  name: 'CompanySettingsPremiumView',
  data() {
    return {
      isPremium: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.crm.company, {
      company: state => state[COMPANY_SCOPES.company].data,
      updateCompanyConfigStatus: state => state[COMPANY_SCOPES.updateCompanyConfig].STATUS,
    }),
  },
  async created() {
    this.isPremium = this.company.isPremium;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.company, [
      'getCompanyDetails',
      'putCompanyConfig',
    ]),
    ...mapMutations(DOMAINS_MODEL.app.messages.notifications, [
      'notify',
    ]),
    async savePremium() {
      if (this.isPremium !== this.company.isPremium) {
        await this.putCompanyConfig({
          companyUuid: this.company.uuid,
          config: {
            key: 'premium',
            value: this.isPremium,
          },
        });
        if (this.updateCompanyConfigStatus.ERROR) {
          this.isPremium = this.company.isPremium;
        } else {
          this.notify({ message: 'Premium config has been updated' });
          await this.getCompanyDetails({ companyUuid: this.company.uuid });
        }
      }
    },
  },
};
</script>
<template>
  <div class="CompanySettingsPremiumView">
    <h1 class="mb-2">
      Premium
    </h1>
    <ui-card data-test-id="premium-view">
      <ui-toggle
        :value="isPremium"
        :text="isPremium ? 'Yes' : 'No'"
        :label="$t('CRM.Companies.Settings.premium.label')"
        :disabled="updateCompanyConfigStatus.LOADING"
        data-test-id="form-toggle"
        name="premium"
        @changevalue="({ detail }) => {
          isPremium = detail;
          savePremium();
        }"
      />
    </ui-card>
  </div>
</template>
