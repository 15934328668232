var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CompanySettingsMappingCategoriesView",
      attrs: { "data-test-id": "mapping_categories-view" },
    },
    [
      _c("h1", { staticClass: "mb-2" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("CRM.Companies.Settings.mappingCategories.tabTitle")
            ) +
            " "
        ),
      ]),
      _c("MuiAlgoliaList", {
        ref: "mappingCategoriesTable",
        attrs: {
          index: _vm.ALGOLIA_INDEXES.vehicleCategories,
          "table-config": _vm.contentCells,
          "item-actions": _vm.algoliaItemActions,
          filters: _vm.tableFilter,
          "empty-message": _vm.getNoResultsLabel("categories"),
          "data-test-id": "vehicle_categories-table",
        },
      }),
      _vm.isEditModalOpen
        ? _c("MappingCategoriesEditModal", {
            attrs: {
              "dedicated-category-uuid": _vm.dedicatedCategoryUuid,
              "dedicated-category-name": _vm.dedicatedCategoryName,
              "open-category-uuid": _vm.openCategoryUuid,
              "cs-operator-uuid": _vm.csOperatorUuid,
              "data-test-id": "open_categories-modal",
            },
            on: { "close-modal": _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }